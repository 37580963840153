<template>
  <div class="box">
    <div class="top">
      <div class="logo"><img src="@/assets/img/logo.png" style="width:100%;height:100%" alt=""></div>
      <!-- @click="$router.push('/index')" -->
      <div class="baogao">我的營養素報告</div>
      <!-- <div class="shop" @click="upto()">營養品商城</div> -->
      <div class="flex">
        <div class="hend"><img src="~@/assets/img/hend.png" style="width:100%" alt=""></div>
        <div class="name">{{infos.name}},您好</div>
        <div class="login" @click="getout()">登出</div>
      </div>
    </div>
    <div class="vbvb">
      <div class="topv">
        <div class="one">夥伴管理介面</div>
        <div class="two" @click="$router.push('/report')">銷售報表</div>
        <div class="three">我的顧客</div>
      </div>
      <div class="fist">
        <div class="fistq">
          <div>顧客清單</div>
        </div>
        <el-table empty-text="系統統計中" :loading="loading" :data="pagedata.rows" style="width: 100%" :row-class-name="tableRowClassName" :show-header="false">
          <el-table-column prop="name" label="姓名" width="880">
          </el-table-column>

          <el-table-column>

            <template slot-scope="scope">
              <el-button size="mini" @click="handleClick(scope.row)">開啟</el-button>
            </template>
          </el-table-column>

        </el-table>
        <!-- <el-button type="text" @click="dialogTableVisible = true">打开嵌套表格的 Dialog</el-button> -->

        <el-dialog title="問卷列表" :visible.sync="dialogTableVisible">
          <el-table :data="gridData" empty-text="系統統計中" :border="false">
            <el-table-column property="name" label="姓名" width="190"></el-table-column>
            <el-table-column property="createTime" label="日期" width="190"></el-table-column>

            <el-table-column property="phone" label="手機號碼" width="190"></el-table-column>
            <el-table-column property="age" label="年龄" width="190"></el-table-column>
            <el-table-column width="100">

              <template slot-scope="scope">
                <el-button size="mini" @click="handleClickq(scope.row)">開啟</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-dialog>
        <el-pagination class="fy" layout="prev, pager, next" @current-change="current_change" :total="pagedata.totalRows" background>
        </el-pagination>

      </div>
    </div>

  </div>
</template>
<script>
//import Header from "../components/header"
import { logout } from "../../api/login.js"
import { getInfo } from "../../api/infomtion.js"
import { removeToken } from "../../utils/auth.js"
import { myustomers, getInfos } from "../../api/myCustomers.js"
export default {
  // components: { Header },
  data() {
    return {
      activeName: "second",

      pagedata: {
        total: 0, //默认数据总数
        pageSize: 10, //每页的数据条数
        currentPage: 1, //默认开始页面
      },
      loading: false,
      dialogTableVisible: false,
      info: {},
      infos: {},
      gridData: [],
    }
  },
  created() {
    this.getInfo()
  },
  mounted() {
    this.rolese()
  },
  methods: {
    upto() {
      window.location.href = "https://www.drnutri.tw/"
    },
    handleClick(data) {
      this.dialogTableVisible = true
      getInfos({ phone: data.phone }).then((res) => {
        this.info = res.data
        this.gridData = this.info
      })
    },
    handleClickq(data) {
      this.$router.push({
        path: "/form",
        query: {
          phone: data.phone,
          id: data.id,
        },
      })
    },
    getInfo() {
      getInfo().then((res) => {
        this.infos = res.data
      })
    },
    getout() {
      this.loading = true
      logout()
      this.$store
        .dispatch("LogOut")
        .then((res) => {
          this.$message({
            message: "退出登入成功",
            type: "success",
          })

          this.$router.push("/")
          removeToken(res.data)
        })
        .catch(() => {
          this.loading = false
        })
    },

    //袋装表格num % 2 == 0
    tableRowClassName({ row, rowIndex }) {
      switch (rowIndex % 4) {
        case 0:
          return
        case 1:
          return "warning-row"
        case 2:
          return
        case 3:
          return "success-row"
      }
      // switch (rowIndex % 2 == 0) {
      //  caches return
      // else {
      //   if (rowIndex == 1) {
      //     return "warning-row"
      //   } else if (rowIndex == 3) {
      //     return "success-row"
      //   }
      // }

      // return ""
    },

    current_change(page) {
      this.pagedata.currentPage = page
      this.rolese()
    },

    rolese() {
      this.loading = true
      myustomers({
        pageSize: this.pagedata.pageSize,
        pageNo: this.pagedata.currentPage,
      }).then((res) => {
        this.pagedata = res.data
        this.loading = false
      })
    },
  },
}
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.fy {
  text-align: right;
  padding: 20px;
}
.topv {
  width: 1200px;
  height: 97px;
  //   background: rgb(198, 226, 187);
  margin: 0 auto;
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 40px;
  .one {
    height: 97px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 97px;
    color: #333333;
    opacity: 1;
  }
  .two {
    height: 97px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 97px;
    color: #707070;
    opacity: 1;
    margin-left: 580px;
  }
  .two:hover {
    height: 97px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 97px;
    color: #333333;
    margin-left: 580px;
    border-bottom: 2px solid #000;
  }
  .three {
    height: 97px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 97px;
    color: #333333;
    margin-left: 60px;
    border-bottom: 2px solid #000;
  }
}
/deep/.el-dialog {
  width: 930px;
  // height: 800px;
}
//带状表格
/deep/.el-table .warning-row {
  background: #fdf5e666;
}
/deep/.cell {
  padding-left: 30px !important;
}
/deep/.el-table .success-row {
  background: #f0f9eb4f;
}
//查看
/deep/.el-button {
  width: 62px;
  height: 30px;
  color: #fff;
  background: rgba(150, 185, 100, 0.39);
  opacity: 1;
  float: right;
  margin-right: 10px;
}
// .vbvb {
//   width: 1064px;
//   margin: 0 auto;
//   display: flex;
// }
//切换蓝
/deep/.el-tabs__item {
  font-size: 30px;
}
.count-chart-wrapper {
  //width: 800px;
  height: 450px;
  margin: 0 auto;
  background-color: #fff;
}
.count-chart {
  position: relative;
  margin: 0 auto;
  width: 1064px;
  height: 450px;
  canvas {
    width: 100%;
    height: 100%;
  }
}
.flex {
  //width: auto;
  display: flex;
  float: right;
  padding: 0 0;
  margin-right: 4%;
  margin-left: auto;
}
.box {
  width: auto;
  height: 1080px;
  min-width: 1300px;
  background: rgba(255, 255, 255, 0.39);
}
/* 顶部 */
.top {
  width: auto;
  height: 143px;
  min-width: 1300px;
  background: rgb(237, 244, 237);

  display: flex;
  align-items: center;
}
.logo {
  width: 405px;
  height: 143px;
  line-height: 0;
  margin: auto 0;
  margin-left: 30px;
  padding: 13px 0;
}
.baogao {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #333333;
  border-bottom: 3px solid #333333;
  margin: 0 auto;
}
.baogao:hover {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #333333;
  border-bottom: 3px solid #333333;
  margin: 0 auto;
}
.shop {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #707070;
  // border-bottom: 3px solid #707070;
  margin-left: 5%;
}
.shop:hover {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #333333;
  border-bottom: 3px solid #333333;
  margin-left: 5%;
}
.hend {
  width: 30px;
  height: 30px;
  line-height: 0;
}
.login {
  width: 44px;
  height: 30px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 30px;
  color: #333333;
  margin-left: 10px;
}
.name {
  height: 30px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 30px;
  color: #333333;
}
.fist {
  width: 1140px;
  margin: 0 auto;
  .fistq {
    height: 50px;
    background: rgb(237, 244, 237);
    margin-top: 31px;
    div {
      height: 50px;
      font-size: 20px;
      font-weight: 500;
      line-height: 50px;
      color: #333333;
      margin-left: 20px;
    }
  }
  /deep/.el-table {
    height: 551px !important;
    overflow-y: auto;
  }

  .fistw {
    display: flex;
    justify-content: space-between;
    div {
      width: 48%;
    }
  }
  .fiste {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: #333333;
    margin: 10px 20px;
    span {
      font-size: 18px;

      font-weight: bold;
      line-height: 25px;
      color: #333333;
    }
  }
  p {
    text-indent: 35px;
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 10px;
    color: #333333;
  }
}
</style>>
