import request from '@/utils/request'
// 获取用户详细信息
export function myustomers(data) {
    return request({
        url: '/memberCustomer/customerList',
        method: 'get',
        params: data,
    })
}
export function getInfos(data) {
    return request({
        url: '/questionnaire/list',
        method: 'get',
        params: data,
    })
}